
.destination {
  .autocomplete__box {
    border: 1px solid #e2e8f0 !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    border-radius: 0.25rem !important;
    color: #4a5568;
    width: 100%;
    line-height: 1.25 !important;
    padding: 0.5rem 0.75rem !important;
  }
}
